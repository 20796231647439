import React from "react";
/**
 * Creates a new Loading.
 * @class
 */ 
	
const Loading = (props) => {
	
  return (
    <React.Fragment>
      <div style={props.css} className="product_loading" >
	  </div>
    </React.Fragment>
  );
};

export default Loading;