import React, { useState } from 'react';
import { validatePin, setPinModal } from '../actions/LocationAction';
import { connect } from 'react-redux';
import { Modal, Row, Button, Form } from 'react-bootstrap';

/**
 * Pinmodal Component is defined
 * @constructor
 * @param {*} props 
 */
const PinModal = (props) => {
    const [pin, setPin] = useState("");

    const onFormSubmit = e => {
        e.preventDefault();
        pinProceedHandler()
    }
    
    /**
     * @function pinProceedHandler
     */
    const pinProceedHandler = () => {
        if (isSixDigitPin()) {
            props.validatePin({"pinCode":pin, "companyid":props.companyid})
            props.setPinModal(false)
        }
    }

    const isSixDigitPin = () => {
        return (pin.length === 6) ? true : false
    }
    
    /**
     * @function pinChangeHandler
     * @param {object} e - pass event to update the value
     */
    const pinChangeHandler = (e) => {
        const value = e.target.value
        if (value.length <= 6) {
            setPin(value)
        }
    }

    return (

        <Modal show={props.pinModalState} centered onHide={onFormSubmit}
            backdrop="static"
            keyboard={false}>
            <Form onSubmit={onFormSubmit}>
                <Modal.Header className="bg-dark pt-1 pb-1">
                    <Modal.Title className="text-light f-size22">Where do you want us to deliver?</Modal.Title>
                    <button type="button" className="close" onClick={e => props.setPinModal(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <div className="col-md-12 p-0">
                            <label className="f-size">Enter Delivery PIN Code :</label>&nbsp;
            <input placeholder="PIN code" type="number"
                                value={parseInt(pin) || ""}
                                onChange={pinChangeHandler} />
                        </div>
                        <div className="col-md-12 p-0 text-right"></div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
    <p className={!props.deliverablePin ? "show-error" : "hide-error"}>{props.msg}</p>
                    <button className="btn btn-success bgpinbutton" type="submit" disabled={!isSixDigitPin()}> Proceed </button>
                </Modal.Footer>
            </Form>
        </Modal>

        /*<Modal show={props.pinModalState}  centered onHide={onFormSubmit}  
        backdrop="static"
        keyboard={false}>
            <Modal.Header className="bg-dark pt-1 pb-1">
                <Modal.Title className="text-light f-size22">Where do you want us to deliver?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <div className="col-md-12 p-0">
                    <label className="f-size">Enter Delivery PIN Code :</label>&nbsp;
                    <input placeholder="PIN code"  type="number"
                        value={parseInt(pin) || ""}
                        onChange={pinChangeHandler} />
                        
                    </div>
                    <div className="col-md-12 p-0 text-right">
                   </div>
                   
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <p className={ pinError ? "show-error" : "hide-error"}>Invalid PIN code</p> 
            <Button variant="success" onClick={onFormSubmit}>
                    Proceed
            </Button>
            </Modal.Footer>
        </Modal> */
    )
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    pin: state.LocationData.pin,
    pinModalState: state.LocationData.pinModalState,
    deliverablePin: state.LocationData.deliverablePin,
    msg:state.LocationData.msg
})
export default connect(mapStateToProps, { validatePin, setPinModal })(PinModal);