import React from "react";
import ImageUtil from '../utils/ImageUtil';

const NotFound = props => {
  return (
    <React.Fragment>
      <p>
        PAGE NOT FOUND
      </p>
      <p>
        <img src={ImageUtil.getImage('bare-tree.png')} alt="Empty Tree" />
      </p>
    </React.Fragment>
  )
}
export default NotFound