import React,{useEffect} from 'react';
import { connect } from 'react-redux';
import {setNotify } from '../actions/UserAction';


const Notification = props => {

  //const notificationClose = <div className="notification-close"><i className="fa fa-times" onClick={()=>props.setNotify(false)} ></i></div>;

  const renderMsg = () => {

    let notificationMsg = "";

    if(props.msg === undefined || props.msg === '' && !props.deliverablePin) {
      notificationMsg = "Choose your delivery location for best experience";
    }else if(props.msg === "" && props.deliverablePin){
      notificationMsg = "";
    }else{
      notificationMsg = props.msg;
    }
    return (!props.deliverablePin) ? <center>{notificationMsg}</center>: null
  }

  return (
   <div className={!props.deliverablePin ? "notification" : ""}>
      { renderMsg() }
   </div>
  );
}

const mapStateToProps = state => ({
  notification: state.UserData.notification,
  msg: state.LocationData.msg,
  deliverablePin: state.LocationData.deliverablePin
})

export default connect(mapStateToProps,{setNotify})(Notification);