import React, {useState} from 'react';
import { connect } from 'react-redux';
import {  ListGroup } from 'react-bootstrap';
import ImageUtil from '../utils/ImageUtil';

 const Scroller =(props) => {
	 const [interv, setInterv] = useState(null);
	 const[range,setRange]=useState([0,props.range-1]); 
	 let range1=[range[0],range[1]];
	 const leftclick=() => {
	 //console.log("in increase");
		 //console.log(range1);
	  let a=range1[0];
	  let b=range1[1];
	  if(a!=0)
	  {
		  a=a-1;
		  b=b-1;
		  let ob=[a,b];
		  range1[0]=ob[0];
		  range1[1]=ob[1];
		  setRange(ob);
	  }
  }
   const rightclick=() => {
	   //console.log("in increase");
	   //console.log(range1);
	  let a=range1[0];
	  let b=range1[1];
	  if(b!=props.data.length-1)
	  {
		  a=a+1;
		  b=b+1;
		  let ob=[a,b];
		  range1[0]=ob[0];
		  range1[1]=ob[1];
		  setRange(ob);
	  }
  }
  
  const left = () => {
	  
        setInterv(setInterval(() => {leftclick()}, 700));
    }	
    const right = () => {
		
        setInterv(setInterval(() => {rightclick()}, 700));
    }
	 let content=[];
	 var i;
	 for(i=0;i<props.data.length;i++)
	 {
		 if(i>=range[0] && i<=range[1])
		 content=[content,props.data[i]];
	 }
	 return (
		<React.Fragment>
		 {((props.data.length>props.range) && (typeof props.nav != "undefined"))?((props.nav.type=="fa")?(<i className={props.nav.left}  onMouseOver={()=>left()} onMouseLeave={()=>clearInterval(interv)} > </i>):(<img alt="left arrow" src={ImageUtil.getImage(props.nav.left)}  onMouseOver={()=>left()} onMouseLeave={()=>clearInterval(interv)} />)):null}
        { content }
		{((props.data.length>props.range) && (typeof props.nav != "undefined"))?((props.nav.type=="fa")?(<i className={props.nav.right}  onMouseOver={()=>right()} onMouseLeave={()=>clearInterval(interv)}> </i>):(<img alt="left arrow" src={ImageUtil.getImage(props.nav.right)}  onMouseOver={()=>right()} onMouseLeave={()=>clearInterval(interv)} />)):null}
		</React.Fragment>
    );	
 }
 
 const mapStateToProps = state => ({
  
});


export default connect(mapStateToProps,{  })(Scroller);




