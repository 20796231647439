import React from "react";
import { Col, Row } from 'react-bootstrap';
import Loading from './Loading'
import { connect } from 'react-redux';
/**
 * Creates a new LoadingProduct.
 * @class
 */
const ProductLoading = (props) => {
	const css=(props.mobileState)?{height:'168.5px',width:'155px',marginLeft:'9px',marginTop:'9px'}:{height:'269px',width:'269px',marginLeft:'9px',marginTop:'9px'};
  return (
    <React.Fragment>
      <div className="prod">
        <Loading css={css} />
        <b className="prod_name">Product Name</b>
      </div>
      <Row>
        <Col className="pl-10">
          <button className="btn mob-font-11px btn-sm bu">
            quantity
      </button>
        </Col>
      </Row>
      <div className="min_height">
        <span className="pl-2">Loading...</span>
      </div>
      <div className="pl-3 pr-3 tab-width-cont col-md-4 col-12">
        <div className="product-action pos-rel-mobile pos-sta-tab">
          <div className="bttn">
            &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
    mobileState: state.CategoryData.isMobileMenu
})

export default connect(mapStateToProps)(ProductLoading);